// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aMZaaTji{display:flex;margin:0 0 40px;position:relative}@media(min-width:667.98px){.aMZaaTji{flex-wrap:wrap;margin:0 0 100px}}.aMZaaTji:before{background-image:linear-gradient(180deg,#4162ff,#b48408);bottom:0;content:\"\";left:-16px;position:absolute;right:-16px;top:0}@media(min-width:667.98px){.aMZaaTji:before{background-image:linear-gradient(90deg,#4162ff 25%,#b48408 75%);left:-50vw;right:-50vw}}.\\+2vZ7EOk{display:flex;max-width:1400px;position:relative}@media(min-width:667.98px){.\\+2vZ7EOk{flex-wrap:wrap}.XZaVLBw1{flex-basis:50%}}._4PJKXOwd{padding-bottom:24px}@media(min-width:667.98px){._4PJKXOwd{padding-bottom:40px}}@media(min-width:947.98px){._4PJKXOwd{padding-bottom:150px}}._9l2eDRKR{display:none}@media(min-width:667.98px){._9l2eDRKR{align-items:flex-end;display:flex}}.ULlgWTpV{height:auto;margin:24px 0}@media(min-width:667.98px){.ULlgWTpV{margin:12% 0 32px;max-width:544px;width:80%}}@media(min-width:947.98px){.ULlgWTpV{margin:17% 0 32px}}.yQbbHtfk{font-size:max(18px,min(2vw,24px));margin:0;padding:0 0 32px}@media(min-width:947.98px){.yQbbHtfk{padding:0 0 42px;width:80%}}.IdnNMmZF{display:none}@media(min-width:667.98px){.IdnNMmZF{display:flex;max-height:700px;max-width:800px;transition:width .15s,height .15s}}.tS6\\+\\+yvf{background-color:var(--gradient-to);background-image:none!important;transition:filter .35s}.tS6\\+\\+yvf:before{background-color:transparent!important}.tS6\\+\\+yvf span{color:var(--color-body)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "aMZaaTji",
	"row": "+2vZ7EOk",
	"cell": "XZaVLBw1",
	"cellTypo": "_4PJKXOwd",
	"cellImage": "_9l2eDRKR",
	"typo": "ULlgWTpV",
	"text": "yQbbHtfk",
	"image": "IdnNMmZF",
	"button": "tS6++yvf"
};
module.exports = ___CSS_LOADER_EXPORT___;
